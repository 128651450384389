.videosIndex {
  margin: 30px 100px;

  h1 {
    color: $orange;
    margin: 0;
    font-size: 32px;
    margin-bottom: 15px;
  }

  .videosList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
    column-gap: 15px;

    .videoItem {
      color: $silver;
      font-size: 12px;
      text-decoration: none;
      border: 1px solid $concrete;
      border-radius: 7px;
      box-shadow: 0 0 5px 0 rgba(0,0,0,.05);
      margin-bottom: 15px;
      background: $white;
      box-sizing: border-box;
      padding: 15px;

      img {
        display:block;
        margin:auto;
      }

      h2{
        margin-bottom: 0px;
      }

      p{
        margin-bottom: 0px;
      }
    }
    
    .videoItem:hover {
      background: $light-orange;
    }
  }
}
