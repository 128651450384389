.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding: 20% 33%;

  .input {
    height: 40px;
    -webkit-appearance: none;
    background-color: #ffffff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    display: inline-block;
    font-size: 15px;
    line-height: 40px;
    padding: 0 15px;
    width: 100%;
    margin-bottom: 15px;
    color: $silver;
  }

  .logo {
    height: auto;
    width: 300px;
    margin-bottom: 40px;
  }

  .loader {
    position: absolute;
    background-color: white;
    min-height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    opacity: 0.8;
  }

  .passwordForgot {
    margin-top: 15px;

    a,
    a:visited {
      color: $silver;
      font-size: 14px;
      text-decoration: none;
    }
    a:hover,
    a:visited:hover {
      color: $black-light;
    }
  }
}

@media (max-width: 768px) {
  .login {
    padding: 20% 10%;
  }
}
