.ranking {
  .user-ranking {
    justify-content: flex-start;
    display: flex;
    border: 1px solid $concrete;
    border-radius: 7px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 45px;
    box-sizing: border-box;
    flex-direction: column;
    min-height: 250px;
    background: $light-orange;
    justify-content: center;

    .spinner {
      margin: 125px auto;
    }

    .header {
      display: flex;
      padding: 15px;
      background: $white;
      border-radius: 7px;

      .avatar {
        min-width: 67px;
        width: 67px;
        height: 67px;
        margin-right: 15px;
      }

      .content {
        display: flex;
        flex-direction: column;

        .full-name {
          color: $black-light;
          font-size: 23px;
        }
      }
    }

    .numbers {
      background: $light-orange;
      display: flex;
      padding: 15px;

      .number {
        color: $orange;
        font-size: 45px;
        font-weight: 300;
      }

      .description {
        color: $black-light;
        font-weight: 400;
      }

      .points {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
      }

      .position {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
    }

    .total-participants {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      text-transform: uppercase;
      color: $orange;
      border-radius: 7px;
    }
  }
}

.areas-ranking {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;

  .area-ranking {
    .ranking {
      display: flex;
      align-items: center;
      max-width: 100%;
      height: 60px;
      border: 1px solid $concrete;
      border-radius: 7px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
      margin-bottom: 15px;
      box-sizing: border-box;
      background: $white;
      padding: 15px;

      .position {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background: $orange;
        color: $white;
        width: 40px;
        height: 40px;
        font-size: 1.3em;
        margin-right: 15px;
      }

      .area-name {
        color: $black-light;
        font-size: 15px;
        text-transform: uppercase;
      }

      .points {
        margin-left: auto;
        color: $orange;
        font-weight: 300;
        font-size: 25px;
      }
    }
  }
}
