.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
  background: $orange;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  transition: all .15s ease;
  color: $white;
  text-transform: uppercase;
  font-size: 15px;
  padding: auto;
  border-radius: 4px;
  font-size: 14px;
  border: 0;
  text-transform: none;

  &:hover {
    opacity: .8;
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
    opacity: .8;
  }
}
