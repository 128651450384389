@import "./variables.scss";
@import "./fonts.scss";
@import "./topnav.scss";
@import "./home.scss";
@import "./videos.scss";
@import "./video.scss";
@import "./ranking.scss";
@import "./rewards.scss";
@import "./spinner.scss";
@import "./login.scss";
@import "./forms.scss";
@import "./buttons.scss";
@import "./arrow.scss";
@import "./admin.scss";
@import "./toaster.scss";
@import "./global.scss";

* {
  box-sizing: border-box;
}

body {
  background-color: $alabaster;
  margin: 0px;
  border-top: 10px solid $orange;
  height: 100vh;
  min-height: 100%;
  width: 100%;
}

#root,
main {
  height: 100%;
}
