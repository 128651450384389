.adminPanel {
  padding: 15px;

  .upperPannel {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .bottomPannel {
    margin-top: 50px;
    h1 {
      text-align: start;
    }
  }

  h1 {
    text-align: center;

  }
}

.table {
  margin-top: 1.5em;
  display: grid;
  border: 1px solid $concrete;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.04);
  background: white;
  overflow: hidden;
}

thead,
tbody,
tr {
  display: contents;
}

th,
td {
  text-transform: capitalize;
  padding: 15px;
  font-size: .85rem;
}

th {
  text-align: left;
  font-weight: normal;
  color: $orange;

}

tr td {
  background: $light-orange;
  border-top: 1px solid white;
}

.tableFooter {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  column-gap: 5px;
  align-items: center;
  .arrow {
    cursor: pointer;
  }
  h1 {
    color: $black-light;
    margin: 0;
    font-size: 1.1rem;
  }
}
