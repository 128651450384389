.home {
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  width: 90%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .home {
    margin: 30px 70px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 35% 65%;
    column-gap: 20px;
    justify-content: flex-start;
    grid-template-rows: 1fr;

    h1 {
      margin: 0;
      margin-bottom: 15px;
    }

    h2 {
      margin: 0 0 0 15px;
    }
  }
}
