.topnav {
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  background-color: #ffffff;
  height: 75px;
  position: relative;
  padding: 0 2%;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  align-items: stretch;
  justify-content: space-between;

  .items {
    display: flex;

    .logo {
      display: flex;
      align-items: center;
      margin-right: 40px;

      img {
        height: auto;
        width: 126px;
        @media (min-width: 768px) {
          width: 170px;
        }
      }
    }

    .nav-sections {
      display: flex;
      align-items: center;
      text-decoration: none;
      text-transform: uppercase;
      background-color: $orange;
      color: $alabaster;
      padding: 0 10px;
      margin: 0 5px;
    }
  }

  .user-session {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    color: $silver;

    img {
      margin-left: 15px;
      width: 36px;
      height: 36px;
    }
  }
}

.reward-types {
  .topnav {
    justify-content: center;
    height: 50px;

    .nav-sections {
      margin: 0 !important;
    }

    .noSelected {
      background-color: white;
      color: $orange;
    }
  }
  .type {
    cursor: pointer;
  }
}
