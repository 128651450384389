.rewards {
  flex: 1.5 1;
  margin-left: 7.5px;
  overflow: scroll;
  height: 100vh;

  .reward {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    border-radius: 7px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    background: $white;
    box-sizing: border-box;
    overflow: hidden;

    .position {
      padding: 5px;
      display: flex;
      justify-content: flex-start;
      background-color: $orange;

      h2 {
        color: white;
        font-weight: 300;
      }
    }

    .reward-description {
      display: grid;
      grid-template-columns: 30% 68%;
      justify-content: space-between;
      @media (min-width: 768px) {
        grid-template-columns: 23% 74%;
      }

      .photo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 5px;

        img {
          height: 126px;
          width: 126px;
          border-radius: 5px;
          margin: 15px 0px;
        }

        .image-unavailable {
          width: 137px;
        }
      }

      .data {
        padding: 15px;

        .name {
          color: $orange;
          font-size: 18px;
        }

        .description {
          color: $black-light;
          font-size: 12px;
        }
      }
    }
  }
}
