@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$headings-font: 'Ubuntu', Helvetica, Arial, sans-serif;
$body-font: 'Cabin', Helvetica, Arial, sans-serif;
$roboto: 'Roboto', sans-serif;

* {
  font-family: $roboto;
}

b {
  font-weight: 300;
}

h1, h2, h3, h6, p, select, span {
  font-weight: 400;
}

h2, a {
  font-weight: 500;
}
