.arrow {
  position: relative;
  content: "";
  display: inline-block;
  width: .7em;
  height: .7em;
  border-right: .2em solid $orange;
  border-top: .2em solid $orange;
  margin: 1em;
  cursor: pointer;
}

.left {
  transform: rotate(225deg);
}

.right {
  transform: rotate(45deg);
}
