input.input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #c8ccd4;
  background: $alabaster;
  border-radius: 0;
  color: $black-light;
  transition: all .15s ease;
  outline: none;

  &:focus {
    border-bottom: 2px solid $orange;
  }
}
